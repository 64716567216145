<template>
    <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">     
        <el-form ref="form" id="add_bill">
            <h6>Add Bill</h6>
            <div class="row ">
                 <div class="col-xl-12" style="margin-top:10px;">
                    <div style="display:flex;">
                        <span class="date_field">
                            <div class="label_field">Supplier Name</div>
                            <div>
                               <select name="cars" id="cars" class="select_field">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                                </select>
                            </div>
                        </span>
                        <span class="date_field ml-5">
                            <div class="label_field">Entered By</div>
                            <div>
                                <input class="field_input" />
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-xl-12" style="margin-top:5px;margin-bottom:10px">
                    <hr style="width:100%">
                </div>
                <div class="col-xl-8">
                  <div style="display:flex;">
                     <span class="date_field">
                        <div class="label_field">Date</div>
                        <div>
                            <el-date-picker                     
                                placeholder="DD-MM-YYYY"
                                type="date"
                                format="yyyy-MM-dd"
                                style="width: 85% !important"                                
                                >
                            </el-date-picker>
                        </div>
                     </span>
                     <span class="date_field">
                        <div class="label_field">Invoice #</div>
                        <div><input class="field_input" /></div>
                    </span>
                    <span class="date_field ml-5">
                        <div class="label_field">Ship Date</div>
                        <div>
                            <el-date-picker                     
                                placeholder="DD-MM-YYYY"
                                type="date"
                                format="yyyy-MM-dd"
                                style="width: 85% !important"                                
                                >
                            </el-date-picker>
                        </div>
                    </span>
                    <span class="date_field">
                        <div class="label_field">Ship Via</div>
                        <div>
                            <el-select >                                
                            </el-select>
                        </div>
                  </span>
                  </div>
                  <div style="display:flex; margin-top:10px">
                     <span class="date_field">
                        <div class="label_field">Customer P.O #</div>
                       <div><input class="field_input" /></div>
                     </span>
                     <span class="date_field ml-3">
                        <div class="label_field">Invoice #</div>
                        <div><input class="field_input" /></div>
                    </span>
                    <span class="date_field ml-3">
                        <div class="label_field">Terms</div>
                        <div>
                                <el-select >                                
                                </el-select>
                        </div>
                    </span>
                    <span class="date_field ml-3">
                        <div class="label_field">Rep</div>
                        <div><input class="field_input" /></div>
                  </span>
                    <span class="date_field ml-3">
                        <div class="label_field">F.O.B</div>
                        <div><input class="field_input" /></div>
                  </span>
                  </div>
                </div>
                <div class="col-xl-4">
                   <div style="display:flex;">
                      <span class="date_field">
                        <div class="label_field">Invoice To</div>
                        <div>
                            <el-input
                                type="textarea"
                                :rows="4"
                                placeholder="Please input" >
                            </el-input>
                        </div>
                     </span>
                      <span class="date_field ml-3">
                        <div class="label_field">Shipping To</div>
                        <div>
                            <el-input
                                type="textarea"
                                :rows="4"
                                placeholder="Please input" >
                            </el-input>
                        </div>
                     </span>
                   </div>      
                </div>
                <div class="col-xl-12 mt-10">
                    <h6>Products</h6>
                    <div>
                     <table border="1px" style="width:100%" id="product">
                         <tr class="product_table_hd">
                             <th style="height:35px;width: 85px">Item</th>
                             <th style="width: 85px;">CTS SKU</th>
                             <th style="width: 150px;">Description</th>
                             <th style="width: 85px;">Current Cost</th>
                             <th style="width: 85px;">Buying Cost</th>
                             <th style="width: 85px;">Regular %</th>
                             <th style="width: 85px;">Selling Price</th>
                             <th style="width: 65px;">Quantity</th>
                             <th style="width: 85px;">Price Each</th>
                             <th style="width: 85px;">Amount</th>
                         </tr>
                         <tr style="border:1px solid #EDEDED;">
                             <td>DFDF22</td>
                             <td>DH10001</td>
                             <td>dsbfsvfdsgvfsdgvfsvgdfgvfgvf fdgdfvgsdfgfdv fdsgfgs</td>
                             <td>20</td>
                             <td>10</td>
                             <td>5</td>
                             <td>12</td>
                             <td>20</td>
                             <td>24</td>
                             <td>240</td>
                         </tr>
                          <tr style="border:1px solid #EDEDED;">
                             <td>DFDF22</td>
                             <td>DH10001</td>
                             <td>dsbfsvfdsgvfsdgvfsvgdfgvfgvf fdgdfvgsdfgfdv fdsgfgs</td>
                             <td>20</td>
                             <td>10</td>
                             <td>5</td>
                             <td>12</td>
                             <td>20</td>
                             <td>24</td>
                             <td>240</td>
                         </tr>
                          <tr style="border:1px solid #EDEDED;">
                             <td>DFDF22</td>
                             <td>DH10001</td>
                             <td>dsbfsvfdsgvfsd</td>
                             <td>20</td>
                             <td>10</td>
                             <td>5</td>
                             <td>12</td>
                             <td>20</td>
                             <td>24</td>
                             <td>240</td>
                         </tr>
                     </table>
                    </div>
                </div>
                  <div class="col-xl-6 mt-20">
                      <div style="display:flex; ">
                      <a class="text-white">
                            <div
                              class="
                                btn btn-sm btn-success
                                font-weight-bold
                                text-uppercase
                              "
                            >
                             Save & Close
                            </div>
                          </a> 
                           <a class="text-white ml-3">
                            <div
                              class="
                                btn btn-sm btn-success
                                font-weight-bold
                                text-uppercase
                              "
                            >
                             Save & New
                            </div>
                          </a> 
                           <a class="text-white ml-3">
                            <div
                              class="
                                btn btn-sm btn-warning
                                font-weight-bold
                                text-uppercase
                              "
                            >
                             Clear
                            </div>
                          </a> 
                      </div>
                  </div>
                   <div class="col-xl-6 mt-10">
                     
                       <div style="display:flex;float:right" >
                           <span style="width:200px">Sub Total</span>
                           <span style="width:85px">$0.00</span>
                       </div>
                        <div style="display:flex;float:right" class="mt-5">
                           <span style="width:200px;display:flex;">Tax <el-select style="width:100px;" class="ml-3"></el-select></span>
                           <span style="width:85px">$0.00</span>
                       </div>
                        <div style="display:flex;float:right" class="mt-3">
                           <span style="width:200px">Total</span>
                           <span style="width:85px">$0.00</span>
                       </div> 
                  </div>
            </div>
        </el-form>
    </div>
    </div>
</template>

<script>

export default {
  components: {  },
  
    name: "create_bill",
     data() {
      return {

      }
     }
}
</script>
<style scoped>

.el-form-item__label{
    font-size: 20px !important;
}
.date_field{
    width: 145px;
}
.label_field{
    font-size: 11px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    margin-bottom: 5px;
}
.field_input{
    -webkit-appearance: none;
    background-color: #F8F8F8;
    background-image: none;
    border-radius: 3px;
    border: 1px solid #d4ccdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-size: 11px;
    height: 26px !important;
    line-height: 30px;
    outline: 0;
    padding: 0 8px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;   
    -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
    box-shadow: 0 0 1px 1px inset #f2f1f9;
   
}
.el-form .el-input__inner{
    font-size: 10px !important;
}

#product th{
    font-size: 11px !important;
    padding-left: 10px;
}
#product td{
    font-size: 11px !important;
    padding-left: 10px;
    height: 22px;
    overflow: auto;
}
 #product tr:first-child {
    background-color:#EDEDED;
    border:1px solid #EDEDED;
}
.product_table_hd{
     background-color: #EDEDED !important;
     border:1px solid #EDEDED;
}

#product tr:nth-child(odd) {background-color: #EFF8FC;}
.el-input__suffix{
    margin-top: 5px !important;
}
.el-select .el-input .el-select__caret{
    margin-top: 7px !important;
}
.el-input__inner{
  background-color: #4b29b1 !important;  
}
.select_field {
   -webkit-appearance: none;
    background-color: #F8F8F8;
    background-image: none;
    border-radius: 3px;
    border: 1px solid #d4ccdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-size: 11px;
    height: 26px !important;
    line-height: 30px;
    outline: 0;
    padding: 0 8px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;   
    -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
    box-shadow: 0 0 1px 1px inset #f2f1f9;
}
</style>

